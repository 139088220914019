import { React } from "react"
import { Global } from "@emotion/react"
import tw, { css, GlobalStyles } from "twin.macro"
import Header from "../components/navigation"
import Container from "../components/container"
import ScrollTop from "../components/scroll-top-button"
import { TabProvider } from "../contexts/tab-context"

const Layout = props => {
  const { children, location } = props
  const isHomePage = location.pathname === "/"
  // const postSeo = props.data?.seo || {}

  const mainMenu = [
    { id: "savethedate", label: "Save the Date!" },
    { id: "faq", label: "FAQ" },
    { id: "kontakt", label: "Kontakt" },
  ]

  const footerMenu = [
    {
      id: "qwe2123",
      label: "Impressum",
      url: "https://www.bundesstiftung-gleichstellung.de/impressum/",
    },
    {
      id: "qwe1231",
      label: "Datenschutzerklärung",
      url: "https://www.bundesstiftung-gleichstellung.de/datenschutzerklaerung/",
    },
  ]

  return (
    <TabProvider>
      <GlobalStyles />
      <Global
        styles={css`
          body {
            ${tw`antialiased font-regular`}
            color: #25303B;
          }
          html {
            scroll-padding-top: 96px;
            @media (prefers-reduced-motion: no-preference) {
              scroll-behavior: smooth;
            }
            @media (min-width: 1024px) {
              scroll-padding-top: 172px;
            }
            @media (min-width: 1240px) {
              scroll-padding-top: 124px;
            }
            /*
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
            */
          }
        `}
      />
      <div
        tw="relative flex min-h-screen flex-col items-stretch"
        className="global-wrapper"
        data-is-root-path={isHomePage}
      >
        <Header mainMenuItems={mainMenu} footernMenuItems={footerMenu} />
        <main
          css={[tw`flex-grow`, !isHomePage && tw`mt-28 lg:mt-48`]}
          role="main"
        >
          {children}
          <ScrollTop />
        </main>

        <footer tw="flex-shrink-0" role="contentinfo">
          <div tw="bg-sand-500">
            <Container>
              <div tw="grid grid-cols-12 gap-6 py-10 md:lg:py-14 lg:py-18">
                <div tw="col-span-12 md:col-span-6 lg:col-span-3">
                  <div tw="">
                    Bundesstiftung Gleichstellung
                    <br />
                    Karl-Liebknecht-Str. 34
                    <br />
                    10178 Berlin
                  </div>
                </div>
                <div tw="col-span-12 md:col-span-6 md:place-self-end lg:col-span-4">
                  <div tw="">
                    <a href="tel:+4930994057026" tw="text-link-negative">
                      +49 30 994 0570-26
                    </a>
                    <br />
                    <a
                      href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                      tw="text-link-negative"
                    >
                      gleichstellungstag@bundesstiftung-gleichstellung.de
                    </a>
                  </div>
                </div>
                <ul tw="col-span-12 flex flex-wrap space-x-4 text-lg font-bold md:col-span-6 md:space-x-5 md:text-xl lg:col-span-5 lg:justify-end lg:place-self-end">
                  {footerMenu.map(({ label, url, id }) => (
                    <li tw="" className="global-nav-item" key={`nav-${id}`}>
                      <a
                        href={url || `/`}
                        tw="py-3 px-0.5 navLink-animated underline-lillac"
                      >
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Container>
          </div>
        </footer>
      </div>
    </TabProvider>
  )
}

export default Layout
