import { React, useEffect, useRef, useState } from "react"
// import { Link } from "gatsby"
import tw from "twin.macro"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Turn as Hamburger } from "hamburger-react"
// import Menu from "./menu"
import Container from "../../components/container"
import Bar from "./bar"
import Logo from "./logo"
import { throttle } from "lodash"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ mainMenuItems, footernMenuItems, breadcrumbs }) => {
  const offset = 80
  // const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || []
  const [showNavigation, toggleNavigation] = useState(false)

  const handleSelectedNavItem = () => {
    toggleNavigation(false)
  }

  const navNode = useRef()

  useEffect(() => {
    showNavigation
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)
  }, [showNavigation])

  const [collapsed, setCollapsed] = useState(false)

  const collapsedStyles = tw`top-18 h-[calc(100vh - 4.5rem)]`
  const normalStyles = tw`top-28 h-[calc(100vh - 7rem)]`
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    const updateScrollState = () => {
      if (isBrowser && window.scrollY > offset && !collapsed) {
        setCollapsed(true)
      }
      if (isBrowser && window.scrollY < offset && collapsed) {
        setCollapsed(false)
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", throttle(updateScrollState, 60))
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", updateScrollState)
      }
    }
  }, [collapsed, isBrowser, offset])

  return (
    <Bar collapsed={collapsed}>
      <Container>
        <div tw="flex items-center justify-between">
          {/* sm:flex-row lg:flex-col lg:items-start xl:flex-row xl:items-end */}
          <Logo collapsed={collapsed} />
          <div
            ref={navNode}
            tw="absolute left-0 z-20 h-screen w-full flex-col items-start bg-lilac-500 pb-2 pt-4 md:py-5 lg:py-8"
            // lg:static lg:top-auto lg:-mb-2.5 lg:flex-row lg:items-center lg:justify-between lg:bg-transparent lg:px-0 lg:py-0 lg:pb-0 xl:w-auto xl:justify-evenly
            css={[
              showNavigation ? tw`flex` : tw`hidden`,
              collapsed && collapsedStyles,
              !collapsed && normalStyles,
            ]}
          >
            <Container>
              <div tw="flex flex-col justify-evenly text-2xl font-bold md:text-3xl px-4 pb-2 md:px-10 md:py-7 lg:py-14 lg:pl-20 xl:pl-80 lg:pr-20">
                {mainMenuItems.map((item, index) => (
                  <AnchorLink
                    to={`/#${item.id}`}
                    // tw="inline-block py-3 text-2xl font-bold navLink-animated navLink-animated-active underline-anthracite md:text-3xl lg:text-5xl"
                    // tw="mr-5 mb-5 inline-block bg-lilac-500 font-bold tracking-widest text-anthracite-500 no-underline transition-colors duration-300 hover:underline-bold lg:mr-7 lg:mb-7"
                    key={`anchor-${index}`}
                    onAnchorLinkClick={handleSelectedNavItem}
                    onClick={handleSelectedNavItem}
                  >
                    <span tw="inline-block py-3 text-2xl font-bold no-underline transition-colors duration-300 hover:underline-bold md:text-3xl lg:text-5xl">
                      {item.label}
                    </span>
                  </AnchorLink>
                ))}
              </div>
            </Container>
            {/* <Menu
              menuItems={mainMenuItems}
              onNavItemClick={handleSelectedNavItem}
              collapsed={collapsed}
            /> */}
            {/*
            <div tw="hidden lg:mb-2.5 lg:flex gap-2">
              <Twitter />
              <Instagram />
            </div>
            */}
            {/* <div tw="mt-6 hidden flex-grow flex-col items-end justify-end text-sm md:flex lg:hidden">
              <ul tw="font-bold text-xl md:text-2xl">
                {footernMenuItems.map(({ label, url, id }) => (
                  <li tw="" key={`nav-${id}`}>
                    <Link to={url || `/`} tw="inline-block py-2">
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
          <div tw="-mr-2 flex items-center gap-3">
            {/* <Twitter />
            <Instagram /> */}
            {(collapsed || showNavigation) && (
              <div tw="z-40">
                <Hamburger
                  toggled={showNavigation}
                  label="Navigation anzeigen"
                  toggle={state => {
                    toggleNavigation(state)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </Bar>
  )
}

export default Header
